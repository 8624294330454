import React, { FC, useEffect } from 'react';
import TrialDaysLeftButton from 'app/components/TrialDaysLeftButton';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import PostsProfileSelector from 'app/modules/posts/components/PostsProfileSelector';
import PostsTopbarAddNewDropdown from 'app/modules/posts/components/PostsTopbarAddNewDropdown';
import LayoutTopBar from 'app/components/layout/LayoutTopBar';
import Skeleton from 'react-loading-skeleton';
import { usePostsContainer } from 'components/posts/context';
import { useMutation } from 'react-query';
import PostApi from 'api/post';
import { useTranslation } from 'react-i18next';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { PostExportVariant } from 'constants/post';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';
import PostsTimeSaversDropdown from '../PostsTimeSaversDropdown';
import PostsFiltersDropdown from '../PostsFiltersDropdown';
import PostsAdditionalActionsDropdown from '../PostsAdditionalActionsDropdown';
import PostsLayoutTobBarDateControls from './postsLayoutTopBar/PostsLayoutTobBarDateControls';
import { TYPE_INSTAGRAM } from '@kontentino/kontentino-constants/Pages';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';
import { useNavigate } from 'react-router';
import { modalRoutes } from 'constants/routes';
import { useToast } from 'app/hooks/useToast';

const PostsLayoutTopBar: FC = () => {
  const postsContainer = usePostsContainer();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const { activeView, postsThumbnailsOn, togglePostsThumbnails } =
    usePostsLayoutState();
  const { setShowGridViewOptionInNavigation } = useAppLayoutState();
  const { mutate: exportPostsMutate } = useMutation(PostApi.exportContents);
  const { isTrial, trialDaysLeft } = useSubscriptionInfo();

  const handleExportPosts = (type: PostExportVariant) => {
    if (type === PostExportVariant.ExternalLink) {
      if (postsContainer.selectedPosts.length === 0) {
        toast(t('noPostsSelectedToShare'), 'warning');
        return;
      }

      navigate({
        hash: modalRoutes.SHARE_PREVIEW,
        search: `?id=${postsContainer.selectedPosts.join(',')}`,
      });
      return;
    }

    exportPostsMutate({
      type,
      dateFrom: postsContainer.filterState.startDate,
      dateTo: postsContainer.filterState.endDate,
      pages: postsContainer.activeState.profilesIds,
      selectedPosts: postsContainer.selectedPosts,
      statuses: postsContainer.filterState.selectedStatuses,
      types: postsContainer.filterState.selectedPseudoTypes,
      labels: postsContainer.filterState.selectedLabels,
      project: postsContainer.filterState.selectedProjects,
      users: postsContainer.filterState.selectedUserIds,
    });
  };

  useEffect(() => {
    setShowGridViewOptionInNavigation(
      postsContainer.activeState.profiles.every(
        (profile) => profile.type === TYPE_INSTAGRAM,
      ),
    );
  }, [postsContainer.activeState, setShowGridViewOptionInNavigation]);

  return (
    <LayoutTopBar shadow className="!tw-gap-2" data-name="posts-topbar">
      {postsContainer.isPagesLoading ? (
        <Skeleton width={300} height={36} />
      ) : (
        <>
          <div className="tw-mr-auto tw-flex tw-flex-1 tw-items-center tw-justify-start tw-gap-2">
            <PostsProfileSelector
              profiles={postsContainer.pages}
              profileGroups={postsContainer.profileGroups}
              selectedProfilesIds={postsContainer.filterState.selectedProfiles}
              selectedProfileGroupsIds={
                postsContainer.filterState.selectedGroups
              }
              onChange={postsContainer.changeFilter}
            />
            <PostsLayoutTobBarDateControls />
          </div>
          <div className="tw-ml-auto tw-flex tw-flex-1 tw-items-center tw-justify-end tw-gap-2">
            <UserPermissionGate
              scopes={[
                UserPermission.POST_DUPLICATE,
                UserPermission.POST_MOVE,
                UserPermission.POST_MASS_CHANGE_POST_STATUS,
                UserPermission.USER_ASSIGN_TASK,
                UserPermission.POST_SEND_FOR_INTERNAL_APPROVAL,
                UserPermission.POST_SEND_FOR_CLIENT_APPROVAL,
                UserPermission.POST_SCHEDULE,
              ]}
              exact={false}
            >
              <PostsTimeSaversDropdown
                selectedPostsIds={postsContainer.selectedPosts}
                activePagesIds={postsContainer.activeState.profilesIds}
              />
            </UserPermissionGate>
            <PostsFiltersDropdown
              filterData={postsContainer.meta}
              activeData={postsContainer.filterState}
              onChange={postsContainer.changeFilter}
              onReset={postsContainer.clearAllFilters}
            />
            <PostsAdditionalActionsDropdown
              onExportOptionSelect={handleExportPosts}
              disabled={{
                exportPosts: postsContainer.posts.allPosts.length === 0,
              }}
              onSortOptionSelect={(orderDirection) =>
                postsContainer.changeFilter({
                  key: 'orderDirection',
                  value: orderDirection,
                })
              }
              selectedSortOption={postsContainer.filterState.orderDirection}
              onTogglePreviews={togglePostsThumbnails}
              arePreviewsOn={postsThumbnailsOn}
              canTogglePreviews={
                activeView === 'calendar' || activeView === 'board'
              }
              canToggleInspirationVisibility={
                activeView === 'calendar' || activeView === 'list'
              }
              canTogglePostsPageVisibility={activeView !== 'grid'}
              canToggleBoostBudgetVisibility={activeView !== 'grid'}
              canTogglePostsLabelsVisibility={activeView !== 'grid'}
            />
            {isTrial && <TrialDaysLeftButton daysLeft={trialDaysLeft} />}
            <PostsTopbarAddNewDropdown />
          </div>
        </>
      )}
    </LayoutTopBar>
  );
};

export default PostsLayoutTopBar;
