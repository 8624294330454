import { BrandLogo, Checkbox } from '@kontentino/ui';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
import clsx from 'clsx';
import React, { FC } from 'react';
import { stopPropagation } from 'utils/mouseEvent';

export type SocialProfile = {
  id: number | string;
  name: string;
  type: number;
  logo?: {
    src: string;
  };
};

type Props = {
  profile: SocialProfile;
  isSelected: boolean;
  onClick: () => void;
};

const SelectSocialProfileOption: FC<Props> = ({
  profile,
  isSelected,
  onClick,
}) => {
  return (
    <div
      className="tw-flex tw-items-center tw-justify-between hover:tw-cursor-pointer"
      onClick={onClick}
    >
      <span className="tw-mr-2 tw-flex tw-items-center">
        <BrandLogo
          src={profile.logo?.src}
          name={profile.name}
          size={24}
          socialMediaType={getSocialMediaTypeForBrandLogo(profile.type)}
          iconSize={12}
          className="tw-mr-3"
        />
        <span
          className={clsx('tw-text-md tw-font-medium ', {
            'tw-text-primary-100': isSelected,
          })}
        >
          {profile.name}
        </span>
      </span>
      <Checkbox
        checked={isSelected}
        onChange={onClick}
        onClick={stopPropagation()}
        size="small"
        title={profile.name}
      />
    </div>
  );
};

export default SelectSocialProfileOption;
